import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import LabelLang from 'src/lang/components/LabelLang';

import styles from './styles.scss';
import FaqItem from './item';

const FaqList = (props) => {
  const { className, data = [], selected = null, isShowAll = false, isOpenAll = false, onToggleItem } = props;

  const [showAll, setShowAll] = useState(isShowAll);

  useEffect(() => {
    setShowAll(isShowAll);
  }, [isShowAll]);

  return showAll ? (
    <div className={cx(className, styles.faqList, showAll && styles.showAll)}>
      {data.map((e, i) => (
        <FaqItem
          key={i}
          onToggle={open => onToggleItem && onToggleItem(i, open)}
          selected={isOpenAll || selected === i}
          {...e}
        />
      ))}
      {data?.length > 3 && (
        <div className={cx(styles.btnToggle, 'toggle')}>
          <button type="button" onClick={() => setShowAll(false)}>
            <LabelLang id="modules.faqs.viewLess" />
          </button>
        </div>
      )}
    </div>
  ) : (
    <div className={cx(className, styles.faqList, showAll && styles.showAll)}>
      {(data?.length > 3 ? [data[0], data[1], data[2]] : data).map((e, i) => (
        <FaqItem
          key={i}
          onToggle={open => onToggleItem && onToggleItem(i, open)}
          selected={isOpenAll || selected === i}
          {...e}
        />
      ))}
      {data?.length > 3 && (
        <div className={cx(styles.btnToggle, 'toggle')}>
          <button id="view-all-faq" type="button" onClick={() => setShowAll(true)}>
            <LabelLang id="modules.faqs.viewAll" />
          </button>
        </div>
      )}
    </div>
  );
};

FaqList.propTypes = {
  data: PropTypes.array,
  selected: PropTypes.bool,
  isShowAll: PropTypes.bool,
  isOpenAll: PropTypes.bool,
  onToggleItem: PropTypes.func,
};

export default React.memo(FaqList);
