import React from 'react';
import GlossaryLang from 'src/lang/components/GlossaryLang';
import FaqsSection from 'src/modules/faqs';

export const HomeFaqData = [
  {
    question: 'What is P2P lending?',
    answer: `
    <p>P2P lending is where one person lends to another in return for interest, without any third party getting in the way. Unlike bank lending, where the bank lends your deposits and keeps the lion’s share of interest, P2P lending lets you keep all the profits yourself.</p>
    <p>On our platform, you also have full control over the term with a choice of anytime withdrawals or 1, 3, and 6-month fixed terms. We differ from other P2P lending platforms in all lending is backed by <a class="glossary-word">collateral</a>. In other words, we secure loans with assets that can be sold easily (cryptocurrencies).</p>
    <p>New to P2P lending? <a rel="nofollow" href="https://myconstant.com/blog/peer-to-peer-lending-what-you-should-know-before-investing" class="underline" target="_blank">Here’s what to know before you invest.</a></p>
    `,
  },
  {
    question: 'What makes MyConstant different from other P2P lending platforms?',
    answer: `
    <p>MyConstant is a collateral-backed peer-to-peer lending platform. Every loan is backed by collateral of up to 200% of the loan amount, which is sold if borrowers default or if its value falls to a threshold, protecting investors (borrowers keep the loan).</p>
    <p>Unlike other platforms, MyConstant pools investor funds into a lending pool or reserve. This means you match instantly, whether you’re a borrower or an investor. We don’t do credit checks and investors can choose instant access investing or one of three fixed terms for our best rates.</p>
    <p>As well as providing you with a platform on which to do business, we also believe in giving you the educational resources to reach your financial goals, fast. To learn more, <a rel="nofollow" href="https://myconstant.com/blog/" class="underline" target="_blank">check out our blog</a>.</p>
    <p><a rel="nofollow" href="https://myconstant.com/blog/11-reasons-to-invest-with-constant" class="underline" target="_blank">Discover 11 reasons to invest with MyConstant.</a></p>
    `,
  },
  {
    question: 'Who can participate?',
    answer: `
    <p>Anyone can participate, as long as you have an internet connection (to use our website) and access to a bank account (to receive a loan or repayment). If you intend to invest fiat (USD), you will also need to pass KYC (Know Your Customer) checks.</p>
    <p><a rel="nofollow" href="https://myconstant.com/blog/how-to-verify-your-id-on-constant-kyc" class="underline" target="_blank">How to verify your ID (KYC).</a></p>
    `,
  },
  {
    question: 'How does MyConstant protect customers’ assets?',
    answer: `
    <p>When you invest, you’re lending your money to a borrower who has put up cryptocurrency (up to 200% of the loan amount) as <a class="glossary-word">collateral</a> for the loan.</p>
    <p>This protects your investment from borrower default and pricing volatility. If the borrower defaults, we sell their collateral to repay you.</p>
    <p>In addition, we use qualified custodial partners (Prime Trust) to manage both fiat (USD) and digital assets. This additional layer of protection helps minimize the threat of theft, mismanagement, or loss of your assets.</p>
    <p><a rel="nofollow" href="https://myconstant.com/blog/how-we-protect-your-money-and-collateral" class="underline" target="_blank">Read more about how we protect your assets.</a></p>
    `,
  },
  // {
  //   question: 'What is a “Loan Originator” loan?',
  //   answer: `
  //   <p>We’ve partnered with loan originators (companies that source borrowers) to offer you a greater choice of loans and better returns. When you invest in a loan from one of our partners, the loan originator will guarantee to buy back the loan if the borrower defaults for 60 days. This repays your principal and earned profit up to and including the 60-day default period.</p>
  //   `,
  // },
  // {
  //   question: 'How do Loan Originator loans work?',
  //   answer: `
  //   <p>When you invest in a Loan Originator loan, you’re lending to a borrower through one of our loan origination partners. Depending on the loan originator, you might receive monthly repayments of the total due, monthly payments of the interest only, or everything at the end of the term – all of which you can withdraw when paid. If the borrower is 60 days late on any of these repayments, the loan originator will buy back the loan and return your principal and earned profit.</p>
  //   `,
  // },
  // {
  //   question: 'What is a loan originator?',
  //   answer: `
  //   <p>A loan originator is a partner that sources borrowers on our behalf. Some arrange loans for borrowers with <a class="glossary-word">collateral</a> – such as cars or property – while others assess borrowers’ credit before issuing loans. In either case, our loan originators all come with a buy-back guarantee. That means if their borrowers default for 60 days or more, the loan originator will buy back the loan, returning your principal and earned profit.</p>
  //   `,
  // },
  // {
  //   question: 'How do you choose loan originators?',
  //   answer: `
  //   <p>Before we onboard a lending company onto our platform, we conduct a thorough due diligence process and analysis of the lending company's profile, background, corporate documents, lending practice and credit policy, historical loan performance as well as their financial strengths.</p>
  //   <p>During this process, we review how the lending company lends to its borrowers, how it conducts its loan monitoring as well as how it pursues the debt recovery process in the event of default. We analyze the key indicators of the lending company's operational control, risk management, and most importantly, its financial health via its audited and interim financial statements.</p>
  //   <p>We also take into account management experience and qualifications and the lending company's transparency and openness. All assessments of these indicators are input into our rating model and we assign a MyConstant Rating to the lending company.</p>
  //   `,
  // },
  // {
  //   question: 'How do you calculate a loan originator’s MyConstant Rating?',
  //   answer: `
  //   <p>We give every loan originator a rating between A and D, A being the best. We only work with C-rated companies and above. Our rating system gives you a guide to the quality of the loans and the loan originator themselves, which determines the risk of lending to that loan originator.</p>
  //   <p>To calculate a rating, we consider many factors, including (but not limited to):</p>
  //   <p>
  //     <ol>
  //       <li>Years of operation.</li>
  //       <li>Country of operation.</li>
  //       <li>Share of the market.</li>
  //       <li>Reputation.</li>
  //       <li>Growth rate.</li>
  //       <li>Risk management.</li>
  //       <li>Transparency.</li>
  //       <li>Financial strength.</li>
  //     </ol>
  //   </p>
  //   <p>We feed all the factors into an algorithmic rating system that produces a final result. However, this rating is not fixed – we regularly review and update ratings so you always have the most up-to-date information before you invest.</p>
  //   <p><a href="#" class="underline" target="_blank">Learn more about the MyConstant Rating system.</a></p>
  //   `,
  // },
  // {
  //   question:
  //     'What’s the difference between a Loan Originator loan and a crypto-backed loan?',
  //   answer: `
  //   <p>Loan Originator loans offer longer terms (6-15 months) and better interest rates (up to 11% <a class="glossary-word">APR</a>). Depending on the loan originator, you might also receive monthly repayments that you can withdraw immediately, whereas crypto-backed borrowers usually repay at the end of the term.</p>
  //   <p>A Loan Originator loan is secured by the loan originator. If borrowers default for a minimum of 60 days, the loan originator will buy back the loan. This returns your principal and earned profit up to and including the 60-day default period.</p>
  //   <p>A crypto-backed loan is secured by collateral. If borrowers default or their collateral falls too much in value, we sell it to repay you. This is usually instant, depending on how quickly we can sell the collateral.</p>
  //   <p>Those are the key differences, but you can see a full comparison on our Loan Originator homepage.</p>
  //   `,
  // },
  // {
  //   question: 'How do you protect my investment on a Loan Originator loan?',
  //   answer: `
  //   <p>First, you have the loan originator’s buy-back guarantee. If borrowers default for 60 days, the loan originator will buy back the loan to return your principal and earned profit up to and including the 60-day period. However, since you rely on the loan originator’s ability to buy back the loan, we’ve given each of them a rating.</p>
  //   <p>The MyConstant Rating is based on the loan originator’s business and performance, including the default rate, their stake in each loan, and types of loan offered. You can use the MyConstant Rating to choose between loan originators, but please remember it is just a guide. A high MyConstant Rating doesn’t guarantee the loan originator will buy back the loan in all cases.</p>
  //   <p>Like all investment, use caution and diversify.</p>
  //   `,
  // },
  // {
  //   question: 'Does the buy-back guarantee protect me in all cases?',
  //   answer: `
  //   <p>In most cases, but not all. Since the loan originator buys back the loan, it depends on their ability to do so, and that’s why we give them a MyConstant rating from A to D, A being the best. Most of the loans we offer will be C or above.</p>
  //   <p>Also, certain countries allow borrowers to request extensions on loans. In this case, an extension isn’t considered a default, so you might wait a bit longer for your returns. If the borrower still hasn’t repaid in full by the end of the extension, the loan originator will buy back the loan as usual.</p>
  //   `,
  // },
  // {
  //   question:
  //     'What happens if a loan originator doesn’t honor the buy-back guarantee?',
  //   answer: `
  //   <p>We don’t expect this to happen, but if it does, there are several options available to us. If they can’t honor the buy-back guarantee due to financial issues, we may negotiate a repayment plan with the loan originator and repay you in full or periodically as they repay. Otherwise, you or us may wish to pursue legal action against the loan originator. In either case, we can’t make any assurances and ask that you diversify across different loan originators to spread your risk.</p>
  //   `,
  // },
  {
    question: 'How do I deposit money for my investments?',
    answer: `
    <p class="font-italic">US customers</p>
    <p>If you’re in the US, you can deposit via ACH by linking a US bank account. You can also wire us, though that is the more expensive option, depending on your bank. (We don’t charge for deposits, but your bank might.)</p>
    <p>For ACH deposits, you need to link your bank account through our ACH processing partner, Plaid. This is very quick and we explain how to do it <a rel="nofollow" href="https://myconstant.com/blog/how-to-link-your-bank-account-for-ach-transfers-on-constant/" class="underline" target="_blank">on our blog</a>.</p>
    <p>ACH deposits through a linked bank account take about 3 business days to appear in your account (possibly longer – it depends on your bank). For non-diamond members, you can only have one pending ACH transaction active at a time.</p>
    <p class="font-italic">Non-US customers</p>
    <p><a rel="nofollow" href="https://myconstant.com/blog/how-to-wire-your-money-to-constant-via-prime-trust/" class="underline" target="_blank">Please wire your funds to us</a>. Full instructions appear when you create your deposit order. Wires take a day or two to reach us (depending on your bank) and then a short processing period of around 1 business day before it appears in your account.</p>
    <p>For more information, <a rel="nofollow" href="https://www.myconstant.com/getPrices" class="underline" target="_blank">please check our service times</a>.</p>
    `,
  },
  {
    question: 'What currencies do you accept?',
    answer: `
    <p>You can send us any currency and we’ll convert it to USD.</p>
    `,
  },
  // {
  //   question: 'Are Loan Originator loans in USD only?',
  //   answer: `
  //   <p>For the moment, yes. We might open up new currencies such as EUR or GBP in the future. You can still invest using other currencies, but please bear in mind we’ll convert your currency to USD upon receipt.</p>
  //   `,
  // },
  {
    question: 'Do you charge any fees?',
    answer: `
    <p>No. All investing, currency conversion, and fiat (USD etc) withdrawals are free. How do we make money? We make a slim profit on the difference between the interest rate you earn and the rate charged to the borrower.</p>
    `,
  },
  // {
  //   question: 'How long does it take to match with a Loan Originator borrower?',
  //   answer: `
  //   <p>Matching is usually instant, but you won’t start earning interest until your funds reach the loan originator. This will involve an international bank transfer. Also, some loan originators do weekly settlement only.</p>
  //   <p>For guidance, you’ll start earning interest within 3-10 business days.</p>
  //   `,
  // },
  // {
  //   question: 'When do I start earning interest on a Loan Originator loan?',
  //   answer: `
  //   <p>You start earning interest as soon as your funds reach the loan originator. This takes 3-10 business days after you’ve matched with a borrower.</p>
  //   <p>Depending on the loan originator, you might be able to withdraw your interest earnings periodically, perhaps monthly. In other cases, you might need to wait until the end of your term before you can withdraw your earnings.</p>
  //   <p>In either case, some loan originators do weekly settlement, which combined with international transfer times, mean it could take 3-10 business days before you can withdraw any repayments.</p>
  //   `,
  // },
  // {
  //   question: 'Can I invest in a portion of a Loan Originator loan?',
  //   answer: `
  //   <p>Yes. You can view a list of available loans and fulfil all or part of each loan order. This helps you diversify, since you can spread your money across more loans.</p>
  //   `,
  // },
];

const renderDescription = item => {
  if (React.isValidElement(item) || typeof item === 'string') return item;
  return null;
};

const TopFaqs = () => {
  return (
    <FaqsSection data={HomeFaqData.map(item => ({
      ...item,
      answer: (
        <GlossaryLang displayHtml={renderDescription(item.answer)} />
      ),
    }))}
    />
  );
};

export default TopFaqs;
