import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { HtmlLang } from 'src/lang/components';
import BlockSection from 'src/components/blocksection';
import { loadLazyImage } from 'src/utils/images';

import FaqList from './list';
import TwoPhoneImg from './img/two_phone.webp';
import styles from './styles.scss';

const FaqsSection = (props) => {
  const { data, isShowAll, isOpenAll, onToggleItem, phoneImage, theme = 'light', desc } = props;

  useEffect(() => {
    loadLazyImage();
  }, []);

  const style = {
    '--text-primary': theme === 'dark' ? 'white' : '#183B56',
    '--text-secondary': theme === 'dark' ? '#8F92A1' : '#5a7184',
    '--background': theme === 'dark' ? '#272A3A' : 'white',
    '--border': theme === 'dark' ? 'transparent' : '#EFF1F4',
  };

  return (
    <BlockSection style={style} className={cx(styles.faqSection, styles[theme])} id="landing-faqs">
      <Row>
        <Col xs={12} md={6}>
          <div className={styles.faqTitle}>
            <h2 className={styles.title}>Frequently <br /> Asked Questions</h2>
            {desc ? desc : <HtmlLang className={styles.desc} id="modules.faqs.desc" />}
          </div>
          <FaqList theme={theme} data={data} isShowAll={isShowAll} isOpenAll={isOpenAll} onToggleItem={onToggleItem} />
        </Col>
        <Col xs={12} md={6} style={{ position: 'relative' }}>
          <img loading="lazy" className={cx(styles.twoPhone, 'lazy-image')} data-src={phoneImage || TwoPhoneImg} alt="" />
        </Col>
      </Row>
    </BlockSection>
  );
};

FaqsSection.propTypes = {
  data: PropTypes.array,
  isShowAll: PropTypes.bool,
  isOpenAll: PropTypes.bool,
  onToggleItem: PropTypes.func,
  phoneImage: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
};

export default React.memo(FaqsSection);
