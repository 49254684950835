import React, { useState } from 'react';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import styled from 'styled-components';
import { Overlay, Popover } from 'react-bootstrap';

import GlossaryView from 'src/components/glossaryView';

const WrapperStyled = styled.div`
  .glossary-word {
    //cursor: pointer;
    //border-bottom: 1px dashed;
    color: inherit !important;
    font-weight: inherit;
    text-decoration: none !important;
  }
  
`;

const PopoverStyled = styled(Popover)`
  border-radius: 10px !important;
  border: 1px solid #ebecef !important;
  box-shadow: 0 2px 20px 0 rgba(71, 70, 110, 0.2) !important;
  background: #ffffff;
  max-width: 320px !important;
  margin-top: 0px;

  .popover-arrow::before {
    display: none;
  }
`;

const GlossaryLang = props => {
  const { id, values, intl, className, displayHtml } = props;
  const html = displayHtml || intl.formatMessage({ id }, values);
  const [popup, setPopup] = useState({ show: false, target: null });

  const handleGlossary = e => {
    if (e?.nativeEvent?.target?.className.includes('glossary-word')) {
      e.stopPropagation();
      setPopup({ show: true, target: e?.nativeEvent?.target });
    } else if (e?.nativeEvent?.target?.className.includes('popover-glossary')) {
      return null;
    } else {
      setPopup({ show: false });
    }
  };

  return (
    <WrapperStyled
      // onClick={handleGlossary}
      onMouseLeave={() => setPopup({ show: false })}
    >
      <div className={className} dangerouslySetInnerHTML={{ __html: html }} />
      <Overlay show={popup?.show} target={popup?.target} placement="top">
        <PopoverStyled>
          <Popover.Body>
            <GlossaryView term={popup?.target?.text} />
          </Popover.Body>
        </PopoverStyled>
      </Overlay>
    </WrapperStyled>
  );
};

export default compose(injectIntl)(GlossaryLang);
