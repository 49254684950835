import React from 'react';
import styled from 'styled-components';

import LocalStore from 'src/services/localStore';

const WrapperStyled = styled.div`
  font-size: 14px;
  a {
    font-size: 14px;
    color: #3e8deb;
  }
`;

const GlossaryView = props => {
  const { term } = props;
  console.log("🚀 ~ file: index.js ~ line 16 ~ term", term)
  const glossary = JSON.parse(LocalStore.get('glossary'));
  if (!glossary || !term) return null;
  const definition = glossary[term.toLowerCase()];
  console.log("🚀 ~ file: index.js ~ line 19 ~ definition", definition)
  return (
    <WrapperStyled className="popover-glossary" dangerouslySetInnerHTML={{ __html: definition || 'No definition' }} />
  );
};

export default GlossaryView;
