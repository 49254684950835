import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './styles.scss';
import moment from 'moment-timezone';

const FaqItem = (props) => {
  const { question, answer, selected = false, onToggle, author, createAt } = props;
  const [open, setOpen] = useState(selected);

  const toggleOpen = () => {
    if (onToggle) onToggle(!open);
    setOpen(!open);
  };

  return (
    <div role="presentation" className={cx(styles.faqItem)} onClick={toggleOpen}>
      {
        author && (
          <div className={styles.author}>
            <div>{author}</div>
            &nbsp;
            (<div>{moment(createAt).format('YYYY-MM-DD HH:mm:ss')}</div>)
          </div>
        )
      }
      <div className={cx(styles.question, open && styles.isActive)} role="presentation">
        <div className={styles.clearBottom}>{question}</div>
      </div>
      <Collapse in={open}>
        <div className={styles.answer}>{answer}</div>
      </Collapse>
    </div>
  );
};

FaqItem.propTypes = {
  question: PropTypes.node,
  answer: PropTypes.node,
  selected: PropTypes.bool,
  onToggle: PropTypes.func,
};

export default React.memo(FaqItem);
